import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'

import HorWrap from '@atoms/HorWrap'

import {
  Wrapper,
  Content,
  Phone1,
  PhonesDown,
  Phone2,
  Phone3,
} from './styles.js'

import phone1img from '@images/works/spotify/black3-1.png'
import phone2img from '@images/works/spotify/black3-2.png'
import phone3img from '@images/works/spotify/black3-3.png'

const Phones1 = () => {
  const [phone1InView, setPhone1InView] = useState(false)
  const [phonesInView, setPhonesInView] = useState(false)

  return (
    <Wrapper>
      <HorWrap>
        <Content>
          <InView
            onChange={(inView, entry) => inView && setPhone1InView(true)}
            threshold={0.25}
          >
            <Phone1 active={phone1InView}>
              <img src={phone1img} />
            </Phone1>
          </InView>
          <InView
            onChange={(inView, entry) => inView && setPhonesInView(true)}
            threshold={0.25}
          >
            <PhonesDown>
              <Phone2 active={phonesInView}>
                <img src={phone2img} />
              </Phone2>
              <Phone3 active={phonesInView}>
                <img src={phone3img} />
              </Phone3>
            </PhonesDown>
          </InView>
        </Content>
      </HorWrap>
    </Wrapper>
  )
}

export default Phones1
