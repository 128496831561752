import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  padding: 60px 0 0;

  ${({ theme }) => theme.media.m} {
    padding: 120px 0 0;
  }
`

export const Content = styled.div`
  position: relative;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    right: -20vw;
    bottom: 0;
    left: -20vw;
    height: 4%;
    background: white;
  }

  img {
    position: relative;
    width: 90%;
    transition: all 0.8s 0.6s ease;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, -20%, 0);
            opacity: 0;
          `}
  }

  ${({ theme }) => theme.media.l} {
    img {
      width: 80%;
    }
  }
`
