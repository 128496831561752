import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 0 0 70px;
  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
    margin-right: ${({ theme }) => theme.headerPaddings.xs};
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    margin: 0 -5px;
  }
  .slick-list {
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-dots {
    position: absolute;
    bottom: -30px;
    left: 0;
    display: flex !important;

    align-items: center;
    justify-content: center;
    margin: 0;
    width: 98%;
    z-index: 1;

    li {
      position: relative;
      display: inline-block;
      margin: 0 10px;
      width: 36px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.grey2};
      text-indent: -9999px;
      transition: background-color 0.3s ease;

      button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: -9999px;
      }
    }

    li.slick-active {
      background-color: ${({ theme }) => theme.colors.orange};
    }
  }
`

export const Item = styled.div`
  padding: 0 5px;
  img {
    width: 100%;
  }
`
