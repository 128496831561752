import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import Slider from 'react-slick'

import HorWrap from '@atoms/HorWrap/index.js'

import { Wrapper, Item } from './styles.js'

const SliderSection = ({ data }) => {
  const [sectionInView, setSectionInView] = useState(false)

  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: 30,
          dots: true,
        },
      },
    ],
  }

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <HorWrap>
          <Slider {...settings}>
            {data.map(item => (
              <Item key={item.fixed.src}>
                <img src={item.fixed.src} alt={item.fixed.src} />
              </Item>
            ))}
          </Slider>
        </HorWrap>
      </Wrapper>
    </InView>
  )
}

SliderSection.propTypes = {
  data: PropTypes.array,
}

SliderSection.defaultProps = {
  data: null,
}

export default SliderSection
