import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    li {
      ${({ active }) =>
        active
          ? css`
              opacity: 1;
              transform: translate3d(0, 0, 0);
            `
          : css`
              opacity: 0;
              transform: translate3d(0, 30%, 0);
            `}
      transition: 0.6s ease;
    }
  }

  ${({ theme }) => theme.media.s} {
    ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  ${({ theme }) => theme.media.l} {
    ul {
      grid-template-columns: repeat(6, 1fr);
    }
  }
`

export const SingleColor = styled.li`
  padding: 50px 20px;
  aspect-ratio: 1 / 1;
  ${({ bg, text }) =>
    css`
      background-color: ${bg};
      color: ${text};
    `}

  p {
    margin: 0 0 20px;
    font-size: 14px;
    text-transform: uppercase;
  }

  &:nth-child(2) {
    transition-delay: 0.1s;
  }

  &:nth-child(3) {
    transition-delay: 0.2s;
  }

  &:nth-child(4) {
    transition-delay: 0.3s;
  }

  &:nth-child(5) {
    transition-delay: 0.4s;
  }

  &:nth-child(6) {
    transition-delay: 0.5s;
  }
`
