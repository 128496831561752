import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  ${({ bgImageMobile }) =>
    bgImageMobile &&
    `background: center center url(${bgImageMobile}) no-repeat;`}
  background-size: cover;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#D9D9D9')};
  color: ${({ textColor }) => (textColor ? textColor : '#ffffff')};
  aspect-ratio: ${({ aspectRatioMobile }) =>
    aspectRatioMobile ? aspectRatioMobile : '2 / 3'};

  transition: all 0.6s 0.6s ease;
  transform: translate3d(0, ${({ active }) => (active ? '0' : '10%')}, 0);
  opacity: ${({ active }) => (active ? '1' : '0')};

  ${({ theme }) => theme.media.l} {
    ${({ bgImageDesktop }) =>
      bgImageDesktop &&
      `background: center center url(${bgImageDesktop}) no-repeat;`}
    background-size: cover;
    background-color: ${({ bgColor }) => (bgColor ? bgColor : '#D9D9D9')};
    aspect-ratio: ${({ aspectRatio }) => (aspectRatio ? aspectRatio : '2 / 1')};
  }
`

export const Content = styled.article`
  > div {
    transition: opacity 0.8s 0.5s ease;
    opacity: ${({ active }) => (active ? '1' : '0')};

    &:nth-child(2) {
      transition-delay: 0.8s;
    }
  }

  p {
    margin: 20px 0 60px;
    max-width: 600px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.66;
  }

  ${({ theme }) => theme.media.l} {
    display: grid;
    ${({ centered }) =>
      centered
        ? css`
            margin: 0 auto;
            width: 40%;
            grid-template-columns: 1fr;
            grid-column-gap: 10%;
            text-align: center;

            h3 {
              justify-content: center;
            }
          `
        : css`
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 25%;
          `}
    grid-row-gap: 0;
  }
`

export const CustomHeader = styled.h3`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
  font-size: 28px;
  font-weight: 900;

  > span {
    overflow: hidden;
    position: relative;
    display: inline-block;

    > span > span {
      position: relative;
      display: inline-block;
      transform: translate3d(0, ${({ active }) => (active ? '0' : '100%')}, 0);
      transition: transform 0.8s 0s ease;
    }

    span + span {
      padding-left: 5px;
    }

    > span:nth-child(2) > span {
      transition-delay: 0.2s;
    }

    > span:nth-child(3) > span {
      transition-delay: 0.4s;
    }

    > span:nth-child(4) > span {
      transition-delay: 0.6s;
    }

    > span:nth-child(5) > span {
      transition-delay: 0.8s;
    }

    > span:nth-child(6) > span {
      transition-delay: 1s;
    }

    > span:nth-child(7) > span {
      transition-delay: 1.2s;
    }

    > span:nth-child(8) > span {
      transition-delay: 1.4s;
    }

    > span:nth-child(9) > span {
      transition-delay: 1.6s;
    }

    > span:nth-child(10) > span {
      transition-delay: 1.8s;
    }
  }

  ${({ theme }) => theme.media.m} {
    font-size: 42px;

    > span span + span {
      padding-left: 9px;
    }
  }

  ${({ theme }) => theme.media.l} {
    font-size: 52px;

    > span span + span {
      padding-left: 12px;
    }
  }
`
