import styled, { css } from 'styled-components'

export const Wrapper = styled.section``

export const Content = styled.div`
  position: relative;
  margin: 40px 0;
  width: 100%;

  img {
    width: 100%;
  }

  ${({ theme }) => theme.media.m} {
  }

  ${({ theme }) => theme.media.l} {
    width: 100%;
    aspect-ratio: 1.35;
  }
`

export const Phone1 = styled.div`
  transition: all 0.6s 0.3s ease;
  ${({ active }) =>
    active
      ? css`
          transform: translate3d(0, 10%, 0);
          opacity: 1;
        `
      : css`
          transform: translate3d(0, 30%, 0);
          opacity: 0;
        `}

  ${({ theme }) => theme.media.l} {
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
  }
`

export const PhonesDown = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 80px 0 160px;
  width: 100%;

  > div {
    width: 45%;
  }

  ${({ theme }) => theme.media.l} {
    margin-left: 55%;
    width: 45%;
  }
`

export const Phone2 = styled.div`
  transition: all 0.6s 0.6s ease;
  ${({ active }) =>
    active
      ? css`
          transform: translate3d(0, 30%, 0);
          opacity: 1;
        `
      : css`
          transform: translate3d(0, 50%, 0);
          opacity: 0;
        `}
`

export const Phone3 = styled.div`
  transition: all 0.6s 0.9s ease;
  ${({ active }) =>
    active
      ? css`
          transform: translate3d(0, 40%, 0);
          opacity: 1;
        `
      : css`
          transform: translate3d(0, 60%, 0);
          opacity: 0;
        `}
`
