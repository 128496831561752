import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'

import { Wrapper, Up, Down } from './styles.js'

import logoBlackBig from '@images/works/spotify/logo-black-big.png'
import logoBlackSmall from '@images/works/spotify/logo-black-small.png'
import logoWhiteBig from '@images/works/spotify/logo-white-big.png'
import logoWhiteSmall from '@images/works/spotify/logo-white-small.png'

const Logos = () => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <Up active={sectionInView}>
          <div>
            <img src={logoBlackBig} />
          </div>
          <div>
            <img src={logoWhiteBig} />
          </div>
        </Up>
        <Down active={sectionInView}>
          <div>
            <img src={logoBlackSmall} />
          </div>
          <div>
            <img src={logoWhiteSmall} />
          </div>
        </Down>
      </Wrapper>
    </InView>
  )
}

export default Logos
