import React from 'react'
import PropTypes from 'prop-types'
import { Parallax, Background } from 'react-parallax'
import { Wrapper, Image, Video, VideoCurtain } from './styles.js'

import HorWrap from '@atoms/HorWrap'
import OrangeHeader from '@atoms/OrangeHeader'
import PurpleHeader from '@atoms/PurpleHeader'

import HeroPhoto from '@images/works/spotify/hero-desktop.png'

const Hero = ({ active, data, path }) => {
  return (
    <Wrapper active={active}>
      <Video active={true} path={path}>
        <div>
          <Parallax strength={100}>
            <Background className="custom-bg">
              <Image src={HeroPhoto} active={active} />
            </Background>
          </Parallax>
        </div>
        <VideoCurtain active={active}>
          <div></div>
          <div></div>
          <div></div>
        </VideoCurtain>
      </Video>
      <HorWrap>
        <OrangeHeader active={active} delay={2.8}>
          01
        </OrangeHeader>
        <PurpleHeader active={active} delay={3} size="big" color="black">
          {data.header}
        </PurpleHeader>
      </HorWrap>
    </Wrapper>
  )
}

Hero.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Hero.defaultProps = {
  active: false,
  children: null,
}

export default Hero
