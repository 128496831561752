import styled, { css } from 'styled-components'
import BgImage from '@images/works/jayne/gradient-bg.png'
import BgImageFlipped from '@images/works/jayne/gradient-bg-flipped.png'
import BgImage2 from '@images/works/jayne/gradient-bg2.png'
import BgImage2Flipped from '@images/works/jayne/gradient-bg2-flipped.png'

export const Wrapper = styled.section`
  padding: 80px 0;

  ${({ theme }) => theme.media.m} {
    padding: 100px 0;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
  color: ${({ white }) => (white ? 'white' : 'black')};

  > p {
    max-width: 600px;
    line-height: 1.66;
    transition: all 0.6s ease;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, 20%, 0);
            opacity: 0;
          `}

    &:nth-child(1) {
      transition-delay: 0.3s;
    }

    &:nth-child(2) {
      transition-delay: 0.5s;
    }

    &:nth-child(3) {
      transition-delay: 0.7s;
    }

    &:nth-child(4) {
      transition-delay: 0.9s;
    }
  }

  ${({ theme }) => theme.media.m} {
  }

  ${({ theme }) => theme.media.l} {
    > p {
      width: 33%;
    }
  }
`
