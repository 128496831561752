import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'

import HorWrap from '@atoms/HorWrap'

import { Wrapper, Content } from './styles.js'
import ReactMarkdown from 'react-markdown'

const TextSection = ({ white, right, data }) => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <HorWrap>
          <Content active={sectionInView} right={right} white={white}>
            <ReactMarkdown children={data} />
          </Content>
        </HorWrap>
      </Wrapper>
    </InView>
  )
}

export default TextSection
