import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'

import { Wrapper, SingleColor } from './styles.js'

const ColorPallete = ({ data }) => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper active={sectionInView}>
        <ul>
          {data.map(item => (
            <SingleColor bg={item.color.hex} text={item.textColor.hex}>
              <p>{item.name}</p>
              <p>{item.rgb}</p>
              <p>{item.cmyk}</p>
            </SingleColor>
          ))}
        </ul>
      </Wrapper>
    </InView>
  )
}

ColorPallete.propTypes = {
  data: PropTypes.array,
}

ColorPallete.defaultProps = {
  data: null,
}

export default ColorPallete
