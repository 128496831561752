import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  overflow: hidden;
  display: grid;
  width: 100%;

  ${({ theme }) => theme.media.m} {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 0;
  }
`

export const Up = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      transition: all 0.6s 0.3s ease;

      ${({ active }) =>
        active
          ? css`
              height: 0;
            `
          : css`
              height: 100%;
            `}
    }

    &:nth-child(1) {
      background: black;

      &:after {
        background: white;
      }
    }

    &:nth-child(2) {
      background: white;

      &:after {
        background: black;
        transition-delay: 0.9s;
      }
    }
  }

  img {
    position: relative;
    display: block;
    width: 100%;
  }

  ${({ theme }) => theme.media.m} {
  }

  ${({ theme }) => theme.media.l} {
    > div {
      &:nth-child(1) {
        &:after {
          background: white;
        }
      }

      &:nth-child(2) {
        &:after {
          background: black;
          transition-delay: 0.9s;
        }
      }
    }
  }
`

export const Down = styled.div`
  display: flex;

  > div {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: black;
      transition: all 0.6s 1.2s ease;

      ${({ active }) =>
        active
          ? css`
              height: 0;
            `
          : css`
              height: 100%;
            `}
    }

    &:nth-child(1) {
      background: black;

      &:after {
        background: white;
        transition-delay: 1.2s;
      }
    }

    &:nth-child(2) {
      background: white;

      &:after {
        background: black;
        transition-delay: 1.5s;
      }
    }
  }

  img {
    position: relative;
    display: block;
    width: 100%;
  }

  ${({ theme }) => theme.media.l} {
    flex-direction: column;

    > div {
      &:nth-child(1) {
        background: black;

        &:after {
          background: white;
          transition-delay: 0.9s;
        }
      }

      &:nth-child(2) {
        background: white;

        &:after {
          background: black;
          transition-delay: 0.3s;
        }
      }
    }

    > div:nth-child(1) {
      order: 2;
    }
  }
`
