import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'

import { Wrapper, Img1, Img2 } from './styles.js'

import endImg1 from '@images/works/spotify/end1.png'
import endImg2 from '@images/works/spotify/end2.png'

const Final = () => {
  const [section1InView, setSection1InView] = useState(false)
  const [section2InView, setSection2InView] = useState(false)

  return (
    <Wrapper>
      <InView
        onChange={(inView, entry) => inView && setSection1InView(true)}
        threshold={0.25}
      >
        <Img1 active={section1InView}>
          <img src={endImg1} />
        </Img1>
      </InView>
      <InView
        onChange={(inView, entry) => inView && setSection2InView(true)}
        threshold={0.25}
      >
        <Img2 active={section2InView}>
          <img src={endImg2} />
        </Img2>
      </InView>
    </Wrapper>
  )
}

export default Final
