import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'

import { Wrapper, Line1, Line2, Line3, Line4, Line5, Line6 } from './styles.js'

import tile1img from '@images/works/spotify/tiles/tile1.png'
import tile2img from '@images/works/spotify/tiles/tile2.png'
import tile3img from '@images/works/spotify/tiles/tile3.png'
import tile4img from '@images/works/spotify/tiles/tile4.png'
import tile5img from '@images/works/spotify/tiles/tile5.png'
import tile6img from '@images/works/spotify/tiles/tile6.png'
import tile7img from '@images/works/spotify/tiles/tile7.png'
import tile8img from '@images/works/spotify/tiles/tile8.png'
import tile9img from '@images/works/spotify/tiles/tile9.png'
import tile10img from '@images/works/spotify/tiles/tile10.png'
import tile11img from '@images/works/spotify/tiles/tile11.png'
import tile12img from '@images/works/spotify/tiles/tile12.png'

const SlidingImages = () => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0}
    >
      <Wrapper active={sectionInView}>
        <Line1 active={sectionInView}>
          <img src={tile6img} />
          <img src={tile11img} />
        </Line1>
        <Line2 active={sectionInView}>
          <img src={tile4img} />
          <img src={tile5img} />
          <img src={tile10img} />
        </Line2>
        <Line3 active={sectionInView}>
          <img src={tile8img} />
          <img src={tile2img} />
          <img src={tile8img} />
          <img src={tile6img} />
          <img src={tile12img} />
        </Line3>
        <Line4 active={sectionInView}>
          <img src={tile10img} />
          <img src={tile12img} />
          <img src={tile7img} />
          <img src={tile3img} />
          <img src={tile7img} />
          <img src={tile6img} />
        </Line4>
        <Line5 active={sectionInView}>
          <img src={tile6img} />
          <img src={tile6img} />
          <img src={tile1img} />
          <img src={tile11img} />
          <img src={tile8img} />
        </Line5>
        <Line6 active={sectionInView}>
          <img src={tile8img} />
          <img src={tile11img} />
          <img src={tile3img} />
        </Line6>
      </Wrapper>
    </InView>
  )
}

export default SlidingImages
