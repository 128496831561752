import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  z-index: 0;
  position: relative;
  padding: 60px 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 25%;
    background: #131416;
  }

  ${({ theme }) => theme.media.m} {
    padding: 120px 0;
  }

  ${({ theme }) => theme.media.l} {
    &:before {
      height: 30%;
    }
  }
`

export const Img1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    width: 100%;
    transition: all 0.8s 0.3s ease;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, 20%, 0);
            opacity: 0;
          `}
  }
`

export const Img2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 60px 0 0;

  img {
    width: 100%;
    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, 20%, 0);
            opacity: 0;
          `}
  }

  ${({ theme }) => theme.media.l} {
    margin: 120px 0 0;

    img {
      width: 80%;
      transition: all 0.8s 0.3s ease;
      ${({ active }) =>
        active
          ? css`
              transform: translate3d(-5%, 0, 0);
              opacity: 1;
            `
          : css`
              transform: translate3d(-5%, 20%, 0);
              opacity: 0;
            `}
    }
  }
`
