import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Main from '@templates/Main'

import Hero from '@layout/worksBlocks/Spotify/Hero'
import Logos from '@layout/worksBlocks/Spotify/Logos'
import TextSection from '@layout/worksBlocks/Spotify/TextSection'
import BlackBg from '@layout/worksBlocks/Spotify/BlackBg'
import Phones1 from '@layout/worksBlocks/Spotify/Phones1'
import Phones2 from '@layout/worksBlocks/Spotify/Phones2'
import SlidingImages from '@layout/worksBlocks/Spotify/SlidingImages'
import Laptop from '@layout/worksBlocks/Spotify/Laptop'

import Final from '@layout/worksBlocks/Spotify/Final'
import Spacer from '@blocks/Spacer'
import SliderSection from '@blocks/SliderSection'
import Credits from '@blocks/Credits'
import FullBleedImage from '@blocks/FullBleedImage'
import ColorPallete from '@blocks/ColorPallete'

import Next from '@blocks/Next'

import muralImg1mobile from '@images/works/spotify/mural1-mobile.png'
import muralImg1desktop from '@images/works/spotify/mural1-desktop.png'
import muralImg2mobile from '@images/works/spotify/mural2-mobile.png'
import muralImg2desktop from '@images/works/spotify/mural2-desktop.png'

import blackImg1desktop from '@images/works/spotify/black-image1-desktop.png'
import blackImg1mobile from '@images/works/spotify/black-image1-mobile.png'

import SliderImg1 from '@images/works/spotify/slider1-1.png'
import SliderImg2 from '@images/works/spotify/slider1-2.png'
import SliderImg3 from '@images/works/spotify/slider1-3.png'

import BlackImg2 from '@images/works/spotify/black-image2.png'
import BlackImg3 from '@images/works/spotify/black-image3.png'

const ProjectSpotify = ({ data, transitionStatus }) => {
  const pageData = data.datoCmsProjectSpotify
  const [startPage, setStartPage] = useState(false)

  useEffect(() => {
    setTimeout(() => setStartPage(true), 100)
  }, [setStartPage])

  return (
    <Main
      seo={pageData.seoMetaTags}
      enterColor={pageData.color.hex}
      transitionStatus={transitionStatus}
    >
      <Hero
        active={startPage && transitionStatus === 'entered'}
        path="spotify"
        data={{
          header: pageData.name,
        }}
      />
      <Spacer size="m" />
      <Credits data={pageData.credits[0]} />
      <Spacer size="m" />
      <FullBleedImage
        data={{
          backgroundColor: {
            hex: '#ffffff',
          },
          textColor: {
            hex: '#ffffff',
          },
          backgroundMobile: {
            url: muralImg1mobile,
            sizes: {
              aspectRatio: 1.28,
            },
          },
          background: {
            url: muralImg1desktop,
            sizes: {
              aspectRatio: 1.85,
            },
          },
        }}
      />
      <Logos />
      <TextSection data={pageData.textSection1} />
      <ColorPallete data={pageData.colorPalette[0].colors} />
      <TextSection data={pageData.textSection2} right />
      <BlackBg>
        <FullBleedImage
          data={{
            backgroundColor: {
              hex: '#000000',
            },
            textColor: {
              hex: '#000000',
            },
            backgroundMobile: {
              url: blackImg1mobile,
              sizes: {
                aspectRatio: 1.41,
              },
            },
            background: {
              url: blackImg1desktop,
              sizes: {
                aspectRatio: 1.88,
              },
            },
          }}
        />
        <SliderSection
          data={[
            { fixed: { src: SliderImg1 } },
            { fixed: { src: SliderImg2 } },
            { fixed: { src: SliderImg3 } },
          ]}
        />
        <TextSection white data={pageData.textSection3} />
        <Phones1 />
        <FullBleedImage
          data={{
            backgroundColor: {
              hex: '#ffffff',
            },
            textColor: {
              hex: '#ffffff',
            },
            background: {
              url: BlackImg2,
              sizes: {
                aspectRatio: 1.85,
              },
            },
          }}
        />
        <Phones2 />
        <FullBleedImage
          data={{
            backgroundColor: {
              hex: '#ffffff',
            },
            textColor: {
              hex: '#ffffff',
            },
            background: {
              url: BlackImg3,
              sizes: {
                aspectRatio: 1.85,
              },
            },
          }}
        />
        <FullBleedImage
          data={{
            backgroundColor: {
              hex: '#000000',
            },
            textColor: {
              hex: '#000000',
            },
            backgroundMobile: {
              url: muralImg2mobile,
              sizes: {
                aspectRatio: 1.28,
              },
            },
            background: {
              url: muralImg2desktop,
              sizes: {
                aspectRatio: 1.85,
              },
            },
          }}
        />
        <Laptop />
      </BlackBg>
      <TextSection data={pageData.textSection4} />
      <SlidingImages />
      <BlackBg color="#131416">
        <Final />
      </BlackBg>
      <Next data={pageData.nextProject} />
    </Main>
  )
}

export const query = graphql`
  query ProjectSpotifyQuery {
    datoCmsProjectSpotify {
      slug
      name
      seoMetaTags {
        tags
      }
      color {
        hex
      }
      credits {
        id
        header
        description
        credits
      }
      colorPalette {
        id
        colors {
          name
          rgb
          cmyk
          color {
            hex
          }
          textColor {
            hex
          }
        }
      }
      textSection1
      textSection2
      textSection3
      textSection4
      nextProject {
        ... on DatoCmsProject {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectFroodie {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectJayne {
          id
          name
          slug
          color {
            hex
          }
        }
        ... on DatoCmsProjectOracle {
          id
          name
          slug
          color {
            hex
          }
        }
      }
    }
  }
`

export default ProjectSpotify
